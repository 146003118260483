body {
  margin: 0;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //overflow: hidden;

  #root {
    width: 100%;
    height: 100vh;
    background-color: #000;
    background-image: url('./bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: auto;



    .App {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      min-height: 100%;

      padding: 0 50px;
      @media (max-width: 700px) {
        padding: 0 30px;
      }
      @media (max-width: 499px) {
        padding: 0 20px;
      }


      .content {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        padding-bottom: 25px;
        & > *:first-child {
          margin-bottom: auto;
          visibility: hidden;
        }
        & > *:last-child {
          margin-top: auto;
        }

        #footer-link {
          font-size: 15px;
          color: #bebebe;
          display: block;
        }

        .content-inner {
          padding: 20px 0;
        }

        .logo {
          svg {
            width: 430px;
            max-width: calc(100vw - 100px);
            @media (max-width: 700px) {
              width: 270px;
            }

            .st0 {
              fill: #FFFFFF;
            }
          }
        }

        .main-description {
          margin-top: 73px;
          max-width: 1200px;
          color: #fff;
          font-weight: bold;
          font-size: 23px;
          line-height: 40px;
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
          @media (max-width: 700px) {
            margin-top: 48px;
            font-size: 20px;
            line-height: 30px;
            font-weight: 500;
          }
          @media (max-width: 499px) {
            font-size: 18px;
            line-height: 28px;
            margin-top: 35px;
          }
        }

        .sub-description {
          margin-top: 80px;
          max-width: 1200px;
          color: #fff;
          font-size: 19px;
          line-height: 30px;
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
          @media (max-width: 700px) {
            margin-top: 25px;
            font-size: 16px;
            line-height: 24px;
          }
        }

        .form-button {
          display: block;
          width: 200px;
          height: 48px;
          border: 2px solid #fff;
          color: #fff;
          font-size: 22px;
          line-height: 44px;
          font-weight: bold;
          margin: 15px auto 0;
          cursor: pointer;
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
          transition: color 0.15s linear, background-color 0.15s linear;

          &:hover {
            background-color: #fff;
            color: #000;
          }

          @media (max-width: 700px) {
            margin-top: 10px;
            font-size: 20px;
            font-weight: 500;
          }
        }
      }


    }

    .form-dialog {
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;

      @media (max-width: 700px) {
        overflow-y: scroll;
      }

      .cover {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        backdrop-filter: blur(2px);
        display: none;
        justify-content: center;
        align-items: center;

        .message {
          color: #4d4d4d;;
          font-size: 22px;
          @media (max-width: 700px) {
            font-size: 16px;
          }
        }

        &.show {
          display: flex;
        }
      }

      .bg {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        backdrop-filter: blur(2px);
        @media (max-width: 700px) {
          backdrop-filter: blur(4px);
        }
      }

      .fg {
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
//        pointer-events: none;
        //        display: flex;
        //        align-items: center;
        //        justify-content: center;
        padding: 0 50px;
        overflow-y: auto;

        @media (max-width: 700px) {
          min-height: 800px;
          padding: 0 0 100px 0;
        }

        .fg-inner {
          min-height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .dialog {
          .form-close-button {
            position: absolute;
            right: 24px;
            top: 24px;
            width: 27px;
            height: 27px;
            //background-color: pink;
            cursor: pointer;
            &:before {
              content:'';
              background-color: #4d4d4d;
              width: 1px;
              position: absolute;
              height: 141%;
              left: calc(50% - 1px);
              top: -20%;
              transform: rotateZ(-45deg);
            }
            &:after {
              content:'';
              background-color: #4d4d4d;
              width: 1px;
              position: absolute;
              height: 141%;
              left: calc(50% - 1px);
              top: -20%;
              transform: rotateZ(45deg);
            }
            @media (max-width: 700px) {
              right: 19px;
              top: 16px;
              width: 30px;
              height: 30px;
            }
          }

          pointer-events: all;
          max-width: 1000px;
          position: relative;

          @media (min-width: 1100px) {
            width: 1000px;
          }

          background-color: #f0f0f0;
          padding: 50px 100px 50px;

          @media (max-width: 700px) {
            padding: 30px 20px 40px;
          }

          .required-label {
            color: #db1818;
            font-size: 12px;
          }

          .field-name {
            &.name {
              margin-top: 20px;
            }
            &:first-child {
              margin-top: 0;
            }

            margin-top: 25px;
            font-size: 19px;
            color: #4d4d4d;
            font-weight: 500;
            margin-bottom: 5px;

            @media (max-width: 700px) {
              font-size: 14px;
              margin-top: 20px;
            }

            &.required {
              &:after {
                content: ' *';
                color: #db1818;
              }
            }
          }

          input[type="text"], input[type="email"] {
            background-color: #fff;
            color: #4d4d4d;
            font-size: 15px;
            padding: 7px;
            display: block;
            width: 100%;

            @media (max-width: 700px) {
              font-size: 16px;
            }

            &[name='other'] {
              display: inline;
              border-bottom: 1px solid #4d4d4d;
              margin-left: 20px;
              @media (max-width: 700px) {
                margin-left: 10px;
              }

              &[disabled] {
                visibility: hidden;
              }
            }
          }

          textarea {
            background-color: #fff;
            color: #4d4d4d;
            font-size: 15px;
            padding: 7px;
            display: block;
            width: 100%;
            height: 110px;

            @media (max-width: 700px) {
              height: 80px;
              font-size: 16px;
            }
          }

          .notice {
            color: #4d4d4d;
            font-size: 11.5px;
            margin-top: 20px;
            display: flex;
            align-items: center;
            & > *:first-child {
              margin-left: 20px;
              margin-right: 20px;
            }
            & > div {
              line-height: 1.8;
              @media (max-width: 700px) {
                line-height: 1.5;
              }
            }
            @media (max-width: 700px) {
              margin-top: 10px;
            }

            a {
              text-decoration: underline;
            }
          }

          #submit-button {
            display: block;
            margin: 40px auto 0;
            text-align: center;
            width: 250px;
            height: 46px;
            border: 1px solid #4d4d4d;;
            color: #4d4d4d;;
            font-size: 22px;
            line-height: 46px;
            font-weight: bold;
            cursor: pointer;
            transition: color 0.15s linear, background-color 0.15s linear;

            &:hover {
              background-color: #4d4d4d;
              color: #fff;
            }

            @media (max-width: 700px) {
              margin-top: 30px;
              font-size: 20px;
              font-weight: bold;
            }
          }

          .radio-items {
            background-color: #fff;
            padding: 17px 25px;

            @media (max-width: 700px) {
              padding: 10px 15px;
            }

            .radio-row {
              height: 26px;

              @media (max-width: 700px) {
                height: 20px;
              }

              display: flex;
              align-items: center;

              label {
                white-space: nowrap;
                padding-left: 18px;

                input {
                  display: none;

                  &:checked {
                    & + span {
                      &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 7px;
                        height: 7px;
                        top: 6px;
                        left: -20px;
                        background-color: #4d4d4d;
                        border-radius: 50%;
                        @media (max-width: 700px) {
                          left: -15px;
                        }
                      }
                    }
                  }
                }

                span {
                  font-size: 15px;
                  position: relative;
                  @media (max-width: 700px) {
                    font-size: 13px;
                  }

                  &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 13px;
                    height: 13px;
                    top: 3px;
                    left: -23px;
                    border: 1px solid #c2c3c5;
                    border-radius: 50%;
                    @media (max-width: 700px) {
                      left: -18px;
                    }
                  }
                }
              }

            }
          }
        }

        .thanks {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: pink;
          align-items: center;
          text-align: center;
          justify-content: center;
          background-color: #f0f0f0;
          @media (max-width: 700px) {
            padding: 0 50px;
          }

          .thanks-message {
            font-size: 33px;
            font-weight: bold;
            color: #4d4d4d;
            line-height: 1.6;

          }
        }
      }
    }
  }
}

